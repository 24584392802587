@font-face {
    font-family: 'Atto';
    src: url('../fonts/woff/Atto.woff2') format('woff2'),
         url('../fonts/woff/Atto.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Atto';
    src: url('../fonts/woff/Atto-Oblique.woff2') format('woff2'),
         url('../fonts/woff/Atto-Oblique.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Atto';
    src: url('../fonts/woff/Atto-Light.woff2') format('woff2'),
         url('../fonts/woff/Atto-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Atto';
    src: url('../fonts/woff/Atto-LightOblique.woff2') format('woff2'),
         url('../fonts/woff/Atto-LightOblique.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Atto';
    src: url('../fonts/woff/Atto-Medium.woff2') format('woff2'),
         url('../fonts/woff/Atto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Atto';
    src: url('../fonts/woff/Atto-MediumOblique.woff2') format('woff2'),
         url('../fonts/woff/Atto-MediumOblique.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}
