@charset "UTF-8";
/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #fff;
  position: fixed;
  z-index: 9000;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Atto';
  src: url("../fonts/woff/Atto.woff2") format("woff2"), url("../fonts/woff/Atto.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Atto';
  src: url("../fonts/woff/Atto-Oblique.woff2") format("woff2"), url("../fonts/woff/Atto-Oblique.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Atto';
  src: url("../fonts/woff/Atto-Light.woff2") format("woff2"), url("../fonts/woff/Atto-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Atto';
  src: url("../fonts/woff/Atto-LightOblique.woff2") format("woff2"), url("../fonts/woff/Atto-LightOblique.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Atto';
  src: url("../fonts/woff/Atto-Medium.woff2") format("woff2"), url("../fonts/woff/Atto-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Atto';
  src: url("../fonts/woff/Atto-MediumOblique.woff2") format("woff2"), url("../fonts/woff/Atto-MediumOblique.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

/*  RESET CSS */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.ui-loader {
  display: none !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: transparent;
}

/* Type Size */
/* Colors */
/* Border */
/* Selection */
::selection {
  color: #191919 !important;
  background: #fff !important;
  /* WebKit/Blink Browsers */
}

::-moz-selection {
  color: #191919 !important;
  background: #fff !important;
  /* Gecko Browsers */
}

[v-cloak] {
  display: none;
}

body {
  font-family: 'Atto', sans-serif;
  font-size: 18px;
  line-height: 1.2em;
  background: #191919;
  color: #fff;
  letter-spacing: 0em;
  word-spacing: 0.02em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern" 1, "dlig" 1, "ss01" 1;
  overflow-x: hidden;
}

a:any-link {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.type-xs, .item-new {
  font-size: 0.5em;
  line-height: 1.6em;
  letter-spacing: 0.01rem;
  word-spacing: 0rem;
}

.type-s, .slick-caption {
  font-size: 0.8rem;
  line-height: 1.25em;
  letter-spacing: 0.01rem;
  word-spacing: 0rem;
}

.type-m {
  font-size: 1.4em;
  line-height: 1.2em;
}

.type-l {
  font-size: 1.4em;
  line-height: 1.1em;
  letter-spacing: 0em;
}

.type-xl {
  font-size: 1.4em;
  letter-spacing: -0.01em;
  line-height: 1.2em;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#app.show-newsletter .ui-content-left {
  padding-top: 42px;
}

.newsletter-popup {
  padding: .5em;
  display: flex;
  justify-content: center;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1100;
  text-align: center;
}

.newsletter-popup .desktop-br {
  display: none;
}

@media only screen and (max-width: 835px) {
  .newsletter-popup {
    height: auto;
    top: unset;
    bottom: 0;
    padding: .5em 15px;
    justify-content: flex-start;
    text-align: left;
  }
  .newsletter-popup .desktop-br {
    display: inline-block;
  }
}

.newsletter-popup a {
  font-weight: 500;
  color: #000;
  padding-right: 2em;
}

.newsletter-popup a:hover {
  opacity: 0.7;
}

.newsletter-close {
  position: absolute;
  right: 15px;
  top: 13px;
  height: 12px;
  width: 12px;
  background-image: url("../images/cross.png");
  background-size: contain;
  background-position: center;
  cursor: pointer;
}

@media only screen and (max-width: 835px) {
  .newsletter-close {
    top: 17px;
  }
}

@media only screen and (max-width: 835px) {
  .newsletter-close {
    top: 14px;
  }
}

.fade-leave-active {
  animation: fade-out 400ms 600ms;
}

.fade-leave-active h1 {
  opacity: 1;
}

.intro {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  background-color: #191919;
}

@media screen and (min-width: 501px) {
  .intro br {
    display: none;
  }
}

.intro h1 {
  max-width: 17em;
  line-height: 1.2em;
  text-align: center;
  animation: fade-in 400ms;
  animation-fill-mode: forwards;
}

.container-indent {
  padding: 0 .5rem 0 .5rem;
}

.headline {
  vertical-align: bottom;
  color: #191919;
  background: #fff;
  text-align: center;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: .85rem 0 .85rem 0;
  transform: translate3d(0, 0, 0);
  border-radius: 0;
  -webkit-border-radius: 0;
  z-index: 1000;
  margin-bottom: -1px;
  font-weight: 500;
  -webkit-appearance: none;
}

.headline::placeholder {
  color: #191919;
  opacity: 1;
}

.headline:focus::placeholder {
  opacity: 0;
}

.search-status {
  display: inline-block;
  padding: .85rem 0 .85rem 0;
}

.last-child {
  border-bottom: inherit;
}

/* Header */
.menu-left {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 50vw;
  background: #eee;
  text-align: center;
  padding: .5em 0 .5em 0;
  color: #191919;
  background: #fff;
}

.menu-right {
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  width: 50vw;
  background: #eee;
  text-align: center;
  padding: .5em 0 .5em 0;
  color: #191919;
  background: #fff;
}

.header {
  color: #fff;
  padding: .5rem 0 .5rem 0;
}

.header-overlay {
  width: 100%;
  height: 100%;
  text-align: justify;
  text-align-last: justify;
  position: fixed;
  min-height: 12rem;
  border-bottom: 1px solid #fff;
  opacity: .9;
  z-index: 1000;
}

.header-center {
  width: 100%;
  text-align: justify;
  height: 30vh;
  text-align: justify;
  position: relative;
  top: 0;
  left: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

.fulljustify {
  z-index: 90;
  text-align: center;
  position: absolute;
  right: 0;
  left: 0;
  top: 50%;
  margin: auto;
  width: 450px;
  transform: translate(0, -60%);
}

.fulljustify span {
  display: none;
}

.box:hover .title,
.box:focus .title {
  opacity: 1;
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
}

.header-logo-container {
  width: 100%;
  height: auto;
  /* must be explicit, for contrast */
  background: #191919;
  /* autoprefixer helping here */
  filter: contrast(40);
}

.header-logo {
  width: 100%;
  font-size: 0;
  display: block;
  line-height: 0;
  border-bottom: 1px solid #fff;
}

.header-logo-background {
  width: 100%;
  height: auto;
}

.header-half {
  text-align: left;
  box-sizing: border-box;
  vertical-align: top;
  display: inline-block;
}

.header-half td {
  width: 50%;
  padding: 0 .5em 2em 0;
}

.header-language {
  position: absolute;
  right: 0;
  top: 0;
}

/* Cart */
.cart-dismiss {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #191919;
  z-index: 100;
  opacity: 0;
  transition: opacity 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  pointer-events: none;
}

.ui-content-left--cart .cart-dismiss {
  opacity: 0.64;
  pointer-events: all;
}

.cart {
  position: fixed;
  top: 0;
  width: 33.3vw;
  height: auto;
  right: 0;
  z-index: 1200;
  overflow: hidden;
  height: 100%;
  pointer-events: none;
}

.cart-bg {
  position: fixed;
  top: 0;
  right: 0;
  width: 33.3vw;
  height: 100%;
  background-color: #191919;
  transform: translate3d(100%, 0, 0);
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  z-index: 0;
}

.cart-inner {
  transform: translate3d(100%, 0, 0);
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  box-sizing: border-box;
  padding: 1em 1em 0em 1em;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1100;
  pointer-events: none;
}

.cart-open {
  overflow-y: scroll;
  pointer-events: all;
}

.cart-open .cart-inner, .cart-open .cart-bg {
  transform: translate3d(0, 0, 0) !important;
}

.grey {
  color: rgba(255, 255, 255, 0.25);
}

.align-right {
  float: right;
}

.cart-items {
  border-top: 1px solid #fff;
  position: relative;
  margin-top: calc(20vh + 0.75em);
  padding-bottom: calc(3.333em + 20vh);
  pointer-events: all;
}

.cart-items ul > li {
  border-bottom: 1px solid #fff;
  display: flex;
  flex-wrap: nowrap;
  padding: .5em 0 .5em 0;
  cursor: pointer;
}

.cart-items .item-title, .cart-items .item-author {
  white-space: normal;
}

.cart-items .price {
  margin-top: .6rem;
}

.cart-empty {
  padding: 0.75em 0 0.5em 0;
  text-align: center;
  border-bottom: 0.1em solid rgba(255, 255, 255, 0.13);
  margin-bottom: 0.5em;
  color: rgba(255, 255, 255, 0.25);
}

.cart-counter {
  position: fixed;
  top: 1em;
  right: 1em;
  cursor: pointer;
  z-index: 1000;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cart-menu {
  position: fixed;
  bottom: 0;
  width: 33.3vw;
  right: 0;
  display: flex;
  z-index: 0;
  transform: translate3d(calc(100%), 0, 0);
  transition: transform 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  z-index: 1000;
}

.cart-menu .order, .cart-menu .close {
  height: 3em;
  display: block;
  line-height: 3em;
  padding: 0.15em 0em 0em 1em;
  flex-grow: 1;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cart-menu .order {
  background-color: white;
  color: black;
}

.cart-menu .close {
  background-color: #191919;
  color: white;
}

.cart-menu .close:hover {
  color: rgba(255, 255, 255, 0.7);
}

.cart-menu .order:hover {
  background-color: #b9b9b9;
}

.cart-menu--cart {
  transform: translate3d(0, 0, 0);
}

.cart-items .item-author {
  max-width: calc(100% - 1.25em);
}

.cart-items .minus:before {
  visibility: hidden;
}

.cart-items .minus:hover:before {
  visibility: visible;
}

/* Footer */
.footer {
  padding: 1.2em 0 2em 0;
  color: #fff;
}

.footer-p {
  margin-bottom: 1.2em;
}

.footer-p a {
  display: inline-block;
}

.footer-p a:hover {
  opacity: .7;
}

.footer-half {
  text-align: left;
  box-sizing: border-box;
  vertical-align: top;
  display: inline-block;
}

.footer-half td {
  width: 50%;
  padding-right: 1em;
}

/* Col */
.col-50 {
  float: left;
  width: calc(50% - 1rem);
  padding-right: 1rem;
}

@media screen and (max-width: 500px) {
  .col-50 {
    width: 100%;
  }
}

/* Image left */
#imagearea {
  z-index: 0;
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
}

.articleimage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 60%;
  max-height: 50%;
}

/* Image mobile */
.imagearea-mobile {
  display: none;
  width: 100%;
  height: auto;
  margin-top: 0.5em;
  text-align: left;
}

.articleimage-mobile {
  max-width: 50%;
  max-height: 50%;
}

/* Slick */
.slide-inner {
  height: 100vh;
  width: 50vw;
  object-fit: cover;
}

.slide-inner img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slick-container {
  position: fixed;
  top: 0;
  left: 50%;
  height: 100%;
  width: 50%;
}

.slick-overlay {
  background-color: #191919;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  display: none;
}

.slick-caption {
  position: absolute;
  width: 70%;
  max-width: 600px;
  text-align: center;
  color: #fff;
  padding: 1rem;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.slick-caption.black {
  color: #191919;
}

/* Input */
input,
label,
select,
button,
textarea {
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  outline: none;
  -webkit-appearance: none;
  /* Browsers have different default form fonts */
  width: 100%;
  font-family: inherit;
  font-weight: 500;
  font-size: 18px;
  background-color: #191919;
  color: #fff;
  letter-spacing: 0.01em;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #fff;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}

/* List items */
.listview {
  width: 90%;
}

.listview tbody {
  display: block;
  width: 100%;
}

.listview tbody tr {
  width: 100%;
}

.listview tbody tr th {
  padding-right: 1em;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Shop items */
.item {
  overflow: hidden;
  position: relative;
  display: inline-block;
}

.item-author {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.item-title {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.full-item-title {
  overflow: visible;
  white-space: normal;
  text-overflow: none;
}

.item-price {
  flex-shrink: 0;
  display: inline-block;
  margin-left: auto;
  right: 0;
}

.item-new {
  position: absolute;
  left: -1.2rem;
  color: #fff;
  opacity: 0.7;
}

.ui-li-static {
  margin-left: 10vw;
}

/* Left Container*/
.ui-content-left {
  width: 50vw;
  display: block;
  float: left;
  transition: 0.5s opacity;
  overflow: hidden;
}

.container {
  padding: 1rem;
  background: #191919;
}

/* Right Container*/
.ui-content-right {
  width: 50vw;
  display: block;
  height: 100vh;
  left: 50vw;
  position: fixed;
  transition: 0.5s opacity;
}

.ui-content-left--cart,
.ui-content-right--cart {
  pointer-events: none;
}

.clearfix {
  clear: both;
}

.minus::before {
  content: "–";
  flex-shrink: 0;
  width: 1em;
  display: inline-block;
}

.plus::before {
  content: "+";
  flex-shrink: 0;
  width: 1em;
  display: inline-block;
}

.cart-amount::after {
  content: "(0)";
  float: right;
}

.cart-amount::after {
  content: "(0)";
  float: right;
}

.download::after {
  content: url("../images/arrow.svg");
  position: absolute;
  display: inline-block;
  right: 0;
  top: 0;
  height: 1.1rem;
  width: 1.1rem;
  padding: .7em 0 .6em 0;
}

.search-input {
  margin-bottom: 1px;
}

.search-input input {
  display: inline-block;
  padding: .5em;
  border-radius: 0.15em;
}

.search-input li a {
  display: block;
  padding: .5em 0 .5em 0;
  border-radius: 0.15em;
  transition: .5s ease;
  opacity: 1;
}

.input {
  background-color: #191919;
}

.input input {
  display: inline-block;
  padding: .5em;
  border-radius: 0.15em;
}

.input li a {
  display: block;
  padding: .5em 0 .5em 0;
  border-radius: 0.15em;
  transition: .5s ease;
  opacity: 1;
}

.searchResults {
  padding-left: 1em;
}

.input-field {
  width: 90%;
}

.input-button {
  padding: .5em 0 .5em 0;
  width: auto;
  float: right;
}

.button {
  text-align: center;
  cursor: pointer;
  color: #191919;
  background: #fff;
  width: auto;
  padding: .8em 2em .8em 2em;
  display: inline-block;
  opacity: 1s;
  border-radius: 5em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.button:hover {
  opacity: 0.7;
}

.list::after {
  display: block;
  position: relative;
  content: "";
  height: 1px;
  width: 9999px;
  left: -900px;
  background: #fff;
  opacity: 1;
}

.list {
  overflow: hidden;
  position: relative;
  list-style: none;
  padding: 0 .5em 0 .5em;
}

.list .inner {
  padding-left: 1em;
  display: none;
}

.list .inner.show {
  /*display: block;*/
}

.list input {
  display: inline-block;
  padding: .5em 0 .5em 0;
  border-radius: 0.15em;
  opacity: 1;
}

.list li {
  margin: 0;
  position: relative;
}

.list li a {
  display: block;
  padding: .5em 0 .5em 0;
  border-radius: 0.15em;
  transition: .5s ease;
  opacity: 1;
}

.list li a:hover {
  opacity: 0.9;
}

.list li::before {
  display: block;
  position: relative;
  content: "";
  height: 0;
  width: 9999px;
  left: -900px;
  background: #fff;
  opacity: 1;
}

.item-description {
  margin: 1em .5em 1.5em 0;
}

.item-content {
  margin-bottom: 1em;
}

.item-content p {
  margin-bottom: 1.2em;
}

.accordion {
  position: relative;
  list-style: none;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.accordion .item-description {
  margin: 0.5em 0em 1.5em 0;
}

.accordion .item-description p {
  margin-bottom: 1.2em;
}

.accordion .inner {
  padding-left: 1em;
  display: none;
}

.accordion .inner.description-item {
  padding-left: 0em;
}

.accordion .inner.show {
  /*display: block;*/
}

.accordion input {
  display: inline-block;
  padding: .5em 0 .5em 0;
  border-radius: 0em;
  opacity: 1;
  line-height: 1.2em;
}

.accordion li {
  margin: 0;
  position: relative;
}

.accordion li a.toggle {
  display: flex;
  flex-wrap: nowrap;
  padding: .5em 0 .5em 0;
  border-radius: 0.15em;
  cursor: pointer;
  opacity: 1;
  transition-duration: 0s;
}

.accordion li a.toggle:hover {
  opacity: 0.7;
}

.accordion li a.toggle-meta {
  display: block;
  padding: .5em 0 .5em 0;
  border-radius: 0.15em;
  opacity: 1;
  transition-duration: 0s;
}

.accordion li a.toggle-meta:hover {
  opacity: 0.7;
}

.accordion li a.toggle-download {
  display: block;
  padding: .5em 0 .5em 0;
  border-radius: 0.15em;
  opacity: 1;
  transition-duration: 0s;
}

.accordion li a.toggle-download:hover {
  opacity: 0.7;
}

.accordion li::before {
  display: block;
  position: relative;
  content: "";
  height: 1px;
  width: 200%;
  background: #fff;
  opacity: 1;
}

.accordion li:first-child::before {
  display: block;
  position: relative;
  content: "";
  height: 1px;
  left: -1em;
  width: 200%;
  background: #fff;
  opacity: 1;
}

.accordion::after {
  display: block;
  position: relative;
  content: "";
  height: 1px;
  width: 9999px;
  left: -900px;
  background: #fff;
  opacity: 1;
}

@media only screen and (max-width: 950px) {
  .type-xl {
    font-size: 1.2em;
    letter-spacing: -0.01em;
    line-height: 1.2em;
  }
  .fulljustify {
    width: 380px;
  }
}

@media only screen and (min-width: 1600px) {
  body {
    font-size: 20px;
  }
  .fulljustify {
    width: 650px;
  }
}

@media only screen and (max-width: 834px) {
  body, input {
    font-size: 20px;
  }
  .imagearea-mobile {
    display: block;
  }
  .ui-content-left {
    width: 100%;
  }
  .ui-content-right {
    display: none;
  }
  .header-center {
    height: 50vw;
  }
  .cart, .cart-inner, .cart-menu, .cart-bg {
    width: 50vw;
  }
}

@media only screen and (max-width: 500px) {
  body, input {
    font-size: 16px;
  }
  .container {
    padding: 0.7rem;
  }
  .fulljustify {
    width: 300px;
  }
  .fulljustify span {
    display: block;
  }
  .articleimage-mobile {
    max-width: 80%;
    max-height: 80%;
  }
  .accordion input {
    display: inline-block;
    padding: .7em 0 .7em 0;
    border-radius: 0em;
    opacity: 1;
    line-height: 1.2em;
  }
  .accordion li a.toggle {
    padding: .7em 0 .7em 0;
  }
  .accordion li a.toggle-meta {
    padding: .7em 0 .7em 0;
  }
  .accordion li a.toggle-download {
    padding: .7em 0 .7em 0;
  }
  .cart, .cart-inner, .cart-menu, .cart-bg {
    width: 100%;
  }
  .header-center {
    height: 100vw;
  }
}

@media only screen and (max-width: 320px) {
  body, input {
    font-size: 14px;
  }
  .fulljustify {
    width: 260px;
  }
}
